import React from 'react';
import Footer from '../../layout/Footer';
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"

export default function NotFoundScreen() {
  return (
    <>
      <Section>
        <SectionHeader>Not Found</SectionHeader>
      </Section>
      <Footer />
    </>
  );
}