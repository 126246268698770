import styled from "styled-components"
import React from "react"
import { typography } from "../shared/styles"

const Wrapper = styled.section`
  font-size: ${typography.size.h2}px;
  font-weight: ${typography.weight.semibold};

  margin: 0 auto;
  margin-bottom: 48px;
  text-align: center;
  
  svg {
    vertical-align: middle;
  }
`;

export default function SectionHeader({ children }) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}