import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "./hooks/useSiteMetadata"

export default function PageMetadata({
                                       title,
                                       description,
                                       type,
                                       image,
                                       twitterCard,
                                       timeToRead,
                                       children,
                                     }) {
  const { siteUrl } = useSiteMetadata()
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {type && <meta property="og:type" content={type} />}
      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {timeToRead && <meta name="twitter:label1" content="Reading time" />}
      {timeToRead && (
        <meta name="twitter:data1" content={`${timeToRead} min read`} />
      )}
      {image && <meta property="og:image" content={`${siteUrl}${image.src}`} />}
      {image?.width && <meta property="og:image:width" content={image.width} />}
      {image?.height && (
        <meta property="og:image:height" content={image.height} />
      )}
      {children}
    </Helmet>
  )
}