import React from 'react';

import NotFoundScreen from '../components/screens/NotFoundScreen/NotFoundScreen';
import { GlobalStyle } from '../components/shared/global';
import PageMetadata from "../components/page-metadata"
import SiteHeader from "../components/layout/SiteHeader"

export default () => (
  <>
    <PageMetadata title="Not Found" />
    <GlobalStyle />
    <SiteHeader />
    <main>
      <NotFoundScreen />
    </main>
  </>
);